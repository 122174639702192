<template>
  <div class="home">
    <div class="homeCarousel">
      <!-- 轮播图 -->
      <div class="homeCarousel_img">
        <el-carousel
          ref="carousel"
          :height="imgHeight"
          arrow="hover"
          @change="handleIndicatorSelected"
          indicator-position="none"
          :autoplay="true"
        >
          <el-carousel-item>
            <div
              @click="
                goLink(
                  'https://shop389691363.taobao.com/?spm=a230r.7195193.1997079397.2.77371f40wd5lS2'
                )
              "
            >
              <img
                ref="imgH"
                src="@/assets/img/home/banner-home_tbao.jpg"
                @load="imgLoad"
              />
            </div>
          </el-carousel-item>
          <!-- <el-carousel-item v-for="(item, index) in urls" :key="index">
            <div>
              <img :src="item.imgUrl" />
            </div>
          </el-carousel-item> -->
        </el-carousel>
      </div>
      <!-- 自定义指示器 -->
      <div class="homeCarousel_indicator">
        <div
          v-for="(item, index) in 1"
          :key="index"
          @click="handleIndicatorSelected(index)"
          :class="selectStateIndex == index ? 'indicatorSelected' : ''"
        ></div>
      </div>
    </div>
    <div class="homeSoftware">
      <div class="homeSoftware_bg">
        <img src="@/assets/img/home/bg_sjsbie.png" />
      </div>
      <div class="homeSoftware_int">
        <div>冰眸食物视觉识别管理软件</div>
        <div class="homeSoftware_intBox">
          <div>
            <div class="homeSoftware_intLetf" style="margin-top: 54px;">
              <div>
                <div class="homeSoftware_intText">根据天气推荐心情菜谱</div>
                <!-- <div class="homeSoftware_intInfo">
                  轻松设置冰箱分区管理，个人资料信息，消息中心、提供送货地址信息、菜谱发表及操作、记录自己的行程及影像等一应俱全
                </div> -->
              </div>
              <div><img src="@/assets/img/home/imgPic.png" /></div>
            </div>
            <div class="homeSoftware_intLetf" style="margin-top: 56px;">
              <div>
                <div class="homeSoftware_intText">冰箱管理、成员设置简单易用</div>
                <div class="homeSoftware_intInfo">
                  轻松设置冰箱分区管理，个人资料信息，消息中心、提供送货地址信息、菜谱发表及操作、记录自己的行程及影像等一应俱全
                </div>
              </div>
              <div><img src="@/assets/img/home/ic_rjsbie_tq.png" /></div>
            </div>
            <div class="homeSoftware_intLetf">
              <div>
                <div class="homeSoftware_intText">参考食物制作、分享烹饪心得</div>
                <div class="homeSoftware_intInfo">
                  查看各种各样食物制作的方法，让冰箱食物的制作不再是一件难事。也可以把自己食物烹饪技术及方法分享给他人
                </div>
              </div>
              <div><img src="@/assets/img/home/ic_rjsbie_cp.png" /></div>
            </div>
          </div>
          <img src="@/assets/img/home/pic_rjsbie_yjs.png" />
          <div>
            <div class="homeSoftware_intRight" style="margin-top: 126px;">
              <div><img src="@/assets/img/home/ic_rjsbie_sj.png" /></div>
              <div>
                <div class="homeSoftware_intText">冰箱食物进出时间管理</div>
                <div class="homeSoftware_intInfo">
                  提供当前天气状况，可以进行保质提醒、食物分类以及自定义冰箱的存藏区域管理，清楚知道冰箱食物的进出时间记录，让食物保质管理成为可能
                </div>
              </div>
            </div>
            <div class="homeSoftware_intRight">
              <div><img src="@/assets/img/home/ic_rjsbie_gw.png" /></div>
              <div>
                <div class="homeSoftware_intText">食物推荐及小区商铺购物</div>
                <div class="homeSoftware_intInfo">
                  根据需求定位查看附近食材及门店情况，清楚知道当时时令推荐使用的食材信息，根据APP上显示的食材存量及时补充食物，享受购物便捷的服务
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div @click="goRouter('/AboutBingmo')">了解更多</div>
        </div>
      </div>
    </div>
    <div class="homeDevice">
      <img src="@/assets/img/home/device2.jpg">
    </div>
    <div class="homeScenes">
      <div class="homeScenes_title">
        <div>使用场景</div>
        <div>usage scenario</div>
      </div>
      <div class="homeScenes_content">
        <div
          :style="scenesStatus == 1 ? 'width: 50%;' : 'width: 25%;'"
          @mouseenter="handleScenesMouseenter(1)"
        >
          <div>
            <img
              class="homeScenes_contentImg"
              src="@/assets/img/home/bg_wctzhong.png"
            />
          </div>
          <div>
            <div>外出路途当中</div>
            <div>掌握冰箱食物</div>
          </div>
          <div
            :style="scenesStatus == 1 ? 'right: 30px;' : 'right: -424px;'"
            class="homeScenes_contentInfo"
          >
            外出路途中，实时查看家庭成员使用食物的情况，冰眸会及时将逾期食物的信息推送至app
          </div>
        </div>
        <div
          :style="scenesStatus == 2 ? 'width: 50%;' : 'width: 25%;'"
          @mouseenter="handleScenesMouseenter(2)"
        >
          <div>
            <img
              class="homeScenes_contentImg"
              src="@/assets/img/home/bg_gzkyu.png"
            />
          </div>
          <div>
            <div>工作空余时间</div>
            <div>充分利用时间碎片</div>
          </div>
          <div
            :style="scenesStatus == 2 ? 'right: 30px;' : 'right: -424px;'"
            class="homeScenes_contentInfo"
          >
            查看冰箱食物种类信息，借鉴app的食物制作方法，制定午餐、晚餐的菜单，不再为吃什么纠结
          </div>
        </div>
        <div
          :style="scenesStatus == 3 ? 'width: 50%;' : 'width: 25%;'"
          @mouseenter="handleScenesMouseenter(3)"
        >
          <div>
            <img
              class="homeScenes_contentImg"
              src="@/assets/img/home/bg_elzyu.png"
            />
          </div>
          <div>
            <div>休闲娱乐之余</div>
            <div>休闲之余处理食物</div>
          </div>
          <div
            :style="scenesStatus == 3 ? 'right: 30px;' : 'right: -424px;'"
            class="homeScenes_contentInfo"
          >
            及时处理好食物的保质，不造成食物的人为浪费，休闲之余可以及时管理食物
          </div>
        </div>
      </div>
    </div>
    <div class="homeVideo">
      <div class="homeScenes_title">
        <div>使用方式</div>
        <div>Method of use</div>
      </div>
      <video autoplay loop muted src="@/assets/img/home/BmuApp2.mp4"></video>
    </div>
    <!-- <div class="homeContact">
      <div>留下您的联系方式，将有可能免费获得冰眸</div>
      <div>
        <div><input type="text" placeholder="姓名" /></div>
        <div><input type="text" placeholder="邮箱" /></div>
      </div>
      <div>
        <div class="homeContact_btn">立刻申请免费</div>
      </div>
    </div> -->
    <div class="homeApp">
      <div class="homeApp_bg">
        <img src="@/assets/img/home/bg_download2.jpg" />
      </div>
      <div class="homeApp_content">
        <!-- 透明背景板 -->
        <div class="homeApp_bgTran"></div>
        <!-- 背景板内容 -->
        <div class="homeApp_box">
          <div class="homeApp_boxTitle">
            <div>立即获取新的移动应用程序</div>
            <div>Get the new mobile apps today</div>
          </div>
          <div class="homeApp_boxBtn">
            <a
              href="https://vlimsoft-test.oss-cn-shenzhen.aliyuncs.com/apk/iceEyes1.0.1.apk"
            >
              <div>
                <i class="iconfont iconanzhuo"></i>
              </div>
              <div class="homeApp_boxBtn_info">
                <div>Available on the</div>
                <div>App Market</div>
              </div>
            </a>
            <!-- <a>
              <div>
                <i class="iconfont iconpingguo"></i>
              </div>
              <div class="homeApp_boxBtn_info">
                <div>Available on the</div>
                <div>App Stroe</div>
              </div>
            </a> -->
          </div>
        </div>
      </div>
    </div>
    <div class="homeDynamic">
      <div>
        <div>最新动态</div>
        <div>recent news</div>
      </div>
      <div class="homeDynamic_box">
        <div>
          <!-- 主资讯 -->
          <div
            @click="goNewsDetails('/newsDetails', item)"
            class="homeDynamic_boxContent"
            v-for="(item, index) in oneListMain"
            :key="index"
          >
            <!-- 图片 -->
            <div>
              <img :src="item.imgUrl" />
            </div>
            <!-- 内容 -->
            <div class="homeDynamic_boxContent_info">
              <div>
                {{ item.title }}
              </div>
              <div>
                {{ item.info }}
              </div>
              <div>{{ item.time }}</div>
            </div>
          </div>
          <!-- <div class="line"></div> -->
          <!-- 列表 -->
          <div class="homeDynamic_boxList">
            <!-- 列表项 -->
            <div
              @click="goNewsDetails('/newsDetails', item)"
              v-for="(item, index) in oneList"
              :key="index"
            >
              <div class="homeDynamic_boxListText">
                <div>·</div>
                <div>{{ item.title }}</div>
              </div>
              <div>{{ item.time }}</div>
            </div>
          </div>
        </div>
        <div>
          <!-- 主资讯 -->
          <div
            @click="goNewsDetails('/newsDetails', item)"
            class="homeDynamic_boxContent"
            v-for="(item, index) in twoListMain"
            :key="index"
          >
            <!-- 图片 -->
            <div>
              <img :src="item.imgUrl" />
            </div>
            <!-- 内容 -->
            <div class="homeDynamic_boxContent_info">
              <div>
                {{ item.title }}
              </div>
              <div>
                {{ item.info }}
              </div>
              <div>{{ item.time }}</div>
            </div>
          </div>
          <!-- <div class="line"></div> -->
          <!-- 列表 -->
          <div class="homeDynamic_boxList">
            <!-- 列表项 -->
            <div
              @click="goNewsDetails('/newsDetails', item)"
              v-for="(item, index) in twoList"
              :key="index"
            >
              <div class="homeDynamic_boxListText">
                <div>·</div>
                <div>{{ item.title }}</div>
              </div>
              <div>{{ item.time }}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="homeDynamic_btn" @click="goRouter('/News')">更多资讯</div>
      </div>
    </div>
    <div class="homeLink">
      <div>
        <div class="homeLink_title">
          <div>友情链接</div>
          <div>friendly link</div>
        </div>
        <div class="homeLink_link">
          <div @click="goLink('http://www.vlimsoft.com/')">
            <img src="@/assets/img/home/link-vlimsoft.png" />
          </div>
          <div
            @click="
              goLink(
                'https://shop389691363.taobao.com/?spm=a230r.7195193.1997079397.2.77371f40wd5lS2'
              )
            "
          >
            <img src="@/assets/img/home/link-tbdp.png" />
          </div>
          <!-- <div><img src="@/assets/img/home/link-clzq.png" /></div>
          <div><img src="@/assets/img/home/link-gbpx.png" /></div>
          <div><img src="@/assets/img/home/link-mjtp.png" /></div>
          <div><img src="@/assets/img/home/link-gsgt.png" /></div>
          <div><img src="@/assets/img/home/link-ly.png" /></div>
          <div><img src="@/assets/img/home/link-hycw.png" /></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 轮播图高度
      imgHeight: "0",
      // 软件信息-状态
      mouseenterIndex: null,
      // 使用场景-状态
      scenesStatus: 1,
      // 行业资讯主数据列表
      oneList: [
        {
          imgUrl: require("@/assets/img/home/Founding Poster-Cover.png"),
          title: "2021年7月2日",
          time: "2021-07-02 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年7月2日</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:'Microsoft YaHei UI'; 
    letter-spacing:0.4000pt;text-transform:none;font-style:normal;
    font-size:12.5000pt;mso-font-kerning:1.0000pt;background:rgb(255,255,255);
    mso-shading:rgb(255,255,255);">
        <font face="Microsoft YaHei UI">掌握食物库存，确保食物新鲜，守护家人健康！</font>
      </span><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal align=center style="text-align:center;"><img width="356" height="632"
        src="${require("@/assets/img/News/Guangzhou Bingmo Electronics Co., Ltd. was established.jpg")}"><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "掌握食物库存，确保食物新鲜，守护家人健康！",
        },
        {
          imgUrl: require("@/assets/img/News/Third edition on June 30, 2021.jpg"),
          title:
            "2021年6月30日第三版“冰眸”APP及小程序开发完成，用户体验测试开始；",
          time: "2021-06-30 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年6月30日第三版“冰眸”APP及小程序开发完成，用户体验测试开始；</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">冰眸小程序新版正式更新，</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">欢迎大家免费体验！冰眸</font>—冰箱食物管家！
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal align=center style="text-align:center;"><img    
        src="${require("@/assets/img/News/Third edition on June 30, 2021.jpg")}"><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:12.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "冰眸小程序新版正式更新，欢迎大家免费体验！冰眸—冰箱食物管家！",
        },
        {
          imgUrl: require("@/assets/img/News/The new version of Bingmo is being tested online.jpg"),
          title: "2021年5月28日 冰眸新版上线测试中",
          time: "2021-05-28 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);"><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年5月28日 冰眸新版上线测试中</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">冰眸新版</font>app已完成了核心识别开发
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">冰眸助你解决食物屯积麻烦</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">“冰眸”采用了物联网技术、云端服务器。任何环境下都可以通过APP及小程序了解冰箱食物存储情况，根据用户需求随时随地购买补充食物；系统会及时发送食物逾期信息提醒用户及时使用或处理食材
        </font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">冰箱食物进出时间管理</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">提供当前天气状况，可以进行保质提醒、食物分类以及自定义冰箱的存藏区域管理，清楚知道冰箱食物的进出时间记录，让食物保质管理成为可能</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">食物推荐及小区商铺购物</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">根据需求定位查看附近食材及门店情况，清楚知道当时时令推荐使用的食材信息，根据</font>APP上显示的食材存量及时补充食物，享受购物便捷的服务
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">参考食物制作、分享烹饪心得</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">查看各种各样食物制作的方法，让冰箱食物的制作不再是一件难事。也可以把自己食物烹饪技术及方法分享给他人</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">冰箱管理、成员设置简单易用</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">轻松设置冰箱分区管理，个人资料信息，消息中心、提供送货地址信息、菜谱发表及操作、记录自己的行程及影像等一应俱全</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:12.0000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">目前正在上线测试中，欢迎体验并反馈意见，谢谢！</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);"><img width="553" height="399"
        src="${require("@/assets/img/News/The new version of Bingmo is being tested online.jpg")}"><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:0.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "冰眸新版app已完成了核心识别开发",
        },
      ],
      oneListMain: [
        // {
        //   imgUrl: require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png"),
        //   title: "2021年12月8日热烈祝贺广州微林股份有限公司成立！",
        //   time: "2021-12-08 09:00",
        //   content: `
        // <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=MsoNormal  style="text-align:left;" ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        // font-size:18.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >热烈祝贺广州微林股份有限公司成立！</font></span></b><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" >&nbsp;2021年12月8日广州微林股份有限公司宣告正式成立！</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><font face="宋体" >自</font>2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变,致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><br></span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" >&nbsp;广州微林股份有限公司的成立意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。</span><span style="mso-spacerun:'yes';font-family:宋体;font-size:12.0000pt;
        // mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-align:left;" ><img width="303"  height="538"  src="${require("@/assets/img/home/Warm congratulations on the establishment of Guangzhou Weilin Co., Ltd. 220.png")}" ><span style="mso-spacerun:'yes';font-family:宋体;color:rgb(12,12,12);
        // letter-spacing:0.0000pt;text-transform:none;font-style:normal;
        // font-size:13.5000pt;mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></p></div>
        // `,
        //   info: "2021年12月8日广州微林股份有限公司宣告正式成立！自2017年微林软件成立以来，历经五年的五年的稳步发展，从专注于视觉检测和视觉工业自动化的企业，逐步向智能化食物管理的的新方向转变, 致力于让中国4.6亿的家庭的冰箱实现智能化食物管理，并通过生鲜购物和线上烹饪直播等为家庭提供亲切关怀服务。广州微林股份有限公司的成立意味着向“2025年，成为视觉领域的知名企业”这一伟大愿景迈进了重大的一步。",
        // },
      ],
      // 假数据
      twoList: [
        {
          imgUrl: require("@/assets/img/News/img2.jpg"),
          title: "2021年04月冰眸二期改版完成，项目整体规划完成",
          time: "2021-04-01 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年04月冰眸二期改版完成，项目整体规划完成</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">近日，</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:14.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体"></font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:14.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">冰眸项目二期改版和项目整体规划已完成，目前正在对冰眸二期版本进行内部测试中。</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:14.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:14.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);mso-list:l0 level1 lfo1;">
      <![if !supportLists]><span style="font-family:宋体;font-weight:normal;font-size:11.0000pt;
    mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>1、</span></span>
      <![endif]><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">冰箱管理、成员设置简单易用</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span>
    </p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);mso-list:l0 level1 lfo1;">
      <![if !supportLists]><span style="font-family:宋体;font-weight:normal;font-size:11.0000pt;
    mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>2、</span></span>
      <![endif]><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">提供当天天气信息并根据天气情况推荐菜谱；</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span>
    </p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);mso-list:l0 level1 lfo1;">
      <![if !supportLists]><span style="font-family:宋体;font-weight:normal;font-size:11.0000pt;
    mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>3、</span></span>
      <![endif]><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">冰箱食物进出时间管理，可以进行保质提醒、食物分类以及自定义冰箱的区域管理，清楚知道冰箱食物的进出时间记录，让食物保质管理成为可能；</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span>
    </p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;text-align:justify;
    text-justify:inter-ideograph;background:rgb(255,255,255);mso-list:l0 level1 lfo1;">
      <![if !supportLists]><span style="font-family:宋体;font-weight:normal;font-size:11.0000pt;
    mso-font-kerning:1.0000pt;"><span style='mso-list:Ignore;'>4、</span></span>
      <![endif]><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <font face="宋体">参考食物制作、分享烹饪心得，可在</font>app上查看各种各样食物制作的方法，让冰箱食物的制作不再是一件难事。也可以把自己食物烹饪技术及方法分享给他人；
      </span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
    font-size:11.0000pt;mso-font-kerning:1.0000pt;">
        <o:p></o:p>
      </span>
    </p>
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal align=center style="text-align:center;"><img width="334" height="774"
        src="${require("@/assets/img/News/img2.jpg")}"><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "近日，冰眸项目二期改版和项目整体规划已完成，目前正在对冰眸二期版本进行内部测试中。",
        },
        {
          imgUrl: require("@/assets/img/News/Food Butler is online.png"),
          title: "2021年1月31日冰箱食物管家上线公告",
          time: "2021-01-31 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年1月31日冰箱食物管家上线公告</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
    mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
    background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">近日，自主研发具有食物识别功能的冰箱食物管理产品将正式上线。该产品利用当前最为先进的视觉识别算法，通过对手持物品的多目标跟踪和行为判定，对食物进出冰箱的行为进行实时记录。
        </font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
    mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
    background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">冰眸采用物联网</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">技</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">术、通过云端服务实现家庭成员数据共享。用户可在任何环境下通过冰眸</font>
        app及小程序即时知道冰箱内食物存储的情况，系统会将保质旅游区逾期的信息及时发送给用户提醒用户及时使用或处理食材，避免食物浪费及过度囤积的现象发生。
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p></o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
    mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
    background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">冰眸</font>
        app的食物管理功能页面，除了可以进行食物存储、取出记录，还提供当前天气状况显示、进行食物保质消息提醒。根据冰箱的各个功能区域、食物分类自定义设计进行个性化管理，让食物存储状况一目了然。
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p></o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    margin-left:0.0000pt;text-indent:0.0000pt;padding:0pt 0pt 0pt 0pt ;
    mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
    background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">解决当前家用冰箱存储食物的痛点：</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
    mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
    background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
    <font face="宋体">1、智能冰箱价格比较昂贵，现存家庭冰箱功能单一</font>
       </span><span style="mso-spacerun:'yes';font-family:宋体; 
     letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
     font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;">
         <o:p></o:p>
       </span></p>
     <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
     margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
     mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
     background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
     letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
     font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
     background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
         <font face="宋体">2、越来越多的人选择食物团购，因食物屯积造成浪费</font>
       </span><span style="mso-spacerun:'yes';font-family:宋体; 
     letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
     font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;">
         <o:p></o:p>
       </span></p>
     <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
     margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
     mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
     background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
     letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
     font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
     background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
         <font face="宋体">3、因工作时间及外出原因，无法及时了解冰箱存储及保质情况</font>
       </span><span style="mso-spacerun:'yes';font-family:宋体; 
     letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
     font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;">
         <o:p></o:p>
       </span></p>
     <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
     margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
     mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
     background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
     letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
     font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
     background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
         <font face="宋体">4、食物缺乏提醒及时补充食物，学习食物制作方法及分享……</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;">
        <o:p></o:p>
      </span></p>
    <p class=p align=justify style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
    margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
    mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
    background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">根据项目总监介绍，该项目产品将实现</font>“
      </span><span class="15" style="mso-spacerun:'yes';font-family:宋体;color:rgb(17,31,44);
    letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
    mso-shading:rgb(255,255,255);">
        <font face="宋体">掌握食物库存</font>
      </span><span class="15" style="mso-spacerun:'yes';font-family:宋体;color:rgb(17,31,44);
    letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
    mso-shading:rgb(255,255,255);">
        <font face="宋体">，</font>
      </span><span class="15" style="mso-spacerun:'yes';font-family:宋体;color:rgb(17,31,44);
    letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
    mso-shading:rgb(255,255,255);">&nbsp;<font face="宋体">确保食物新鲜</font></span><span class="15" style="mso-spacerun:'yes';font-family:宋体;color:rgb(17,31,44);
    letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
    mso-shading:rgb(255,255,255);">
        <font face="宋体">，</font>
      </span><span class="15" style="mso-spacerun:'yes';font-family:宋体;color:rgb(17,31,44);
    letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
    mso-shading:rgb(255,255,255);">&nbsp;<font face="宋体">守护家人健康</font></span><span class="15" style="mso-spacerun:'yes';font-family:宋体;color:rgb(17,31,44);
    letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
    mso-shading:rgb(255,255,255);">
        <font face="宋体">！</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">”这个产品愿景。公司将于2月初进行首批冰眸产品的免费使用活动，具体活动内容请关注“微林冰眸”公众号。</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:0.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><img width="554" height="311"
        src="${require("@/assets/img/News/Food Butler is online.png")}"><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p>&nbsp;</o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "近日，自主研发具有食物识别功能的冰箱食物管理产品将正式上线。该产品利用当前最为先进的视觉识别算法，通过对手持物品的多目标跟踪和行为判定，对食物进出冰箱的行为进行实时记录。",
        },
        {
          imgUrl: require("@/assets/img/home/pic-jc07_home.jpg"),
          title:
            "2020年10月27日，冰眸项目6项专利获得初审通过，视觉算法模型确定",
          time: "2020-10-27 09:00",
          content: `
        <div class="Section0"  style="layout-grid:15.6000pt;" ><p class=MsoNormal ><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        font-size:14.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >2020年10月</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        font-size:14.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >27日，</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        font-size:14.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >冰眸项目</font>6项专利获得初审通过，视觉算法模型确定</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
        font-size:14.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></b></p><p class=MsoNormal ><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" ></font>“冰眸”项目其中6项专利已获得初审通过，冰眸app核心食物管理算法模型及框架确认，并进行第一阶段开发；</span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-indent:24.0000pt;mso-char-indent-count:2.0000;" ><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >智能冰箱，就是能对冰箱进行智能化控制、对食品进行智能化管理的冰箱类型。具体点说，就是能自动进行冰箱模式调换，始终让食物保持最佳存储状态，可让用户通过手机或电脑，随时随地了解冰箱里食物的数量、保鲜保质信息，可为用户提供健康食谱和营养禁忌，可提醒用户定时补充食品等。</font></span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-indent:24.0000pt;mso-char-indent-count:2.0000;" ><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >随着人民生活水平的不断提高，生活节奏不断加快，冰箱在家庭中起的作用也越来越大，传统的冷藏冷冻保鲜功能已越来越难以满足日常生活的需求。随着物联网技术的不断成熟，并结合信息采集技术和信息处理技术，冰眸建立了一套智能冰箱食品管理系统，实现对冰箱内的食品进出记录。冰箱内的食品信息可以通过冰眸</font>app进行查看，对于每一种食物你都可以设置逾期时间，这样app会对冰箱食物进行逾期提醒，并且会通过冰箱的现有食材提供菜谱推荐。</span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal  style="text-indent:24.0000pt;mso-char-indent-count:2.0000;" ><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><font face="宋体" >在家庭中，作为唯一</font>24小时不停电的家电，通过使用信息自动采集技术、信息传输技术，使得我们对冰箱里的食品实行智能管理与应用。</span><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p></o:p></span></p><p class=MsoNormal ><span style="mso-spacerun:'yes';font-family:宋体;font-weight:normal;
        font-size:12.0000pt;mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></p><p class=MsoNormal ><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
        mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;" ><o:p>&nbsp;</o:p></span></p></div>
        `,
          info: "“冰眸”项目其中6项专利已获得初审通过，冰眸app核心食物管理算法模型及框架确认，并进行第一阶段开发；",
        },
      ],
      twoListMain: [
        {
          imgUrl: require("@/assets/img/home/Bingmo online promotion poster cover.png"),
          title: "2021年8月18日“冰眸app”正式上线。",
          time: "2021-08-18 09:00",
          content: `
        <div class="Section0" style="layout-grid:15.6000pt;">
    <p class=MsoNormal><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">2021年8月18日“冰眸app”正式上线</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">，</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <font face="宋体">运营体系开发完成；</font>
        </span></b><b><span style="mso-spacerun:'yes';font-family:宋体;font-weight:bold;
    font-size:14.0000pt;mso-font-kerning:1.0000pt;">
          <o:p></o:p>
        </span></b></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">近日，</font>“冰眸app”已完成升级并正式上线，冰眸app
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">将在各大应用市场陆续开发用户端下载</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">。</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">通过该应用用户可以对冰箱食物管理，实现</font>“掌握食物库存
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">，</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp;<font face="宋体">确保食物新鲜</font></span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">，</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">&nbsp;<font face="宋体">守护家人健康</font>”这一目的，冰眸</span><span
        style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">—</font>
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <font face="宋体">眸世代</font>&nbsp;鲜百味！
      </span><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p></o:p>
      </span></p>
    <p class=MsoNormal align=center style="text-align:center;"><img width="378" height="671"
        src="${require("@/assets/img/News/A product of Guangzhou Weilin Software Co., Ltd..jpg")}"><span style="mso-spacerun:'yes';font-family:宋体; 
    letter-spacing:0.4000pt;font-weight:normal;text-transform:none;
    font-style:normal;font-size:12.0000pt;mso-font-kerning:1.0000pt;
    background:rgb(255,255,255);mso-shading:rgb(255,255,255);">
        <o:p>&nbsp;</o:p>
      </span></p>
    <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
    mso-bidi-font-family:'Times New Roman';font-size:10.5000pt;mso-font-kerning:1.0000pt;">
        <o:p>&nbsp;</o:p>
      </span></p>
  </div>
        `,
          info: "近日，“冰眸app”已完成升级并正式上线，冰眸app将在各大应用市场陆续开发用户端下载。通过该应用用户可以对冰箱食物管理，实现“掌握食物库存， 确保食物新鲜， 守护家人健康”这一目的，冰眸—眸世代 鲜百味！",
        },
      ],
      // 选中状态标识
      selectStateIndex: 0,
    };
  },
  mounted() {
    this.imgLoad();
    window.addEventListener("resize", this.imgLoad, false);
  },
  destroyed() {
    window.removeEventListener("resize", this.imgLoad, false);
  },
  methods: {
    // 获取图片高度
    imgLoad() {
      this.$nextTick(() => {
        this.imgHeight = `${this.$refs.imgH.height}px`;
      });
    },
    // 处理指示器选中
    handleIndicatorSelected(index) {
      this.selectStateIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
    // 软件信息
    handleMouseenter(i) {
      this.mouseenterIndex = i;
    },
    handleMouseout() {
      this.mouseenterIndex = null;
    },
    // 跳转外网
    goLink(url) {
      window.open(url, "_blank");
    },
    goRouter(url) {
      this.$router.push({
        path: url,
      });
    },
    // 跳转详情
    goNewsDetails(url, item) {
      this.$router.push({
        path: url,
        query: {
          title: item.title,
          time: item.time,
          content: item.content,
        },
      });
    },
    // 使用场景
    handleScenesMouseenter(x) {
      this.scenesStatus = x;
    },
  },
};
</script>

<style scoped>
/* 首页轮播 */
.homeCarousel {
  position: relative;
}
.homeCarousel_img img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 自定义指示器 */
.homeCarousel_indicator {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeCarousel_indicator > div {
  width: 16px;
  height: 16px;
  margin: 0 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
  box-sizing: border-box;
}
/* 指示器选中状态 */
.indicatorSelected {
  border-radius: 35px !important;
  width: 44px !important;
  background-color: #fff;
  transition: width 0.3s;
}

.homeDevice {
  width: 100%;
  height: 900px;
}
.homeDevice img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}

.homeVideo {
  height: 1200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background-image: url("../assets/img/home/bg_bmouuse.jpg");
}
.homeVideo video {
  width: 1440px;
  height: 812px;
  display: flex;
  object-fit: cover;
  margin: 80px 0 90px;
}
.homeSoftware {
  position: relative;
}
.homeSoftware_bg img {
  width: 100%;
  height: 900px;
  display: flex;
  object-fit: cover;
}
.homeSoftware_int {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  color: #fff;
}
.homeSoftware_intBox {
  position: relative;
  width: calc(100% - 720px);
  display: flex;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
}
.homeSoftware_intBox > div {
  width: calc(100% / 3);
}
.homeSoftware_intBox > img {
  width: 240px;
  height: 516px;
  display: flex;
  object-fit: cover;
  margin-left: 6px;
  margin: 0 120px;
}
.homeSoftware_intLetf img,
.homeSoftware_intRight img {
  width: 36px;
  height: 36px;
  display: flex;
  object-fit: cover;
}
.homeSoftware_intLetf {
  margin-top: 85px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.homeSoftware_intLetf > div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}
.homeSoftware_intLetf > div:nth-of-type(1) {
  margin-right: 6px;
}
.homeSoftware_intRight {
  margin-top: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.homeSoftware_intRight > div:nth-of-type(2) {
  margin-left: 6px;
}
.homeSoftware_intText {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 5px;
}
.homeSoftware_intInfo {
  color: #F5F5F5;
  font-size: 14px;
  font-weight: 400;
  padding-top: 6px;
  line-height: 20px;
  border-top: 1px solid #f5f5f5;
}
.homeSoftware_int > div:nth-of-type(1) {
  font-size: 48px;
  font-weight: 500;
  color: #ffffff;
  margin: 70px 0;
  text-align: center;
}
.homeSoftware_int > div:nth-of-type(3) {
  width: 190px;
  height: 54px;
  border: 1px solid #fff;
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}
.homeSoftware_int > div:nth-of-type(3):hover {
  color: #221e1e;
  background-color: #fff;
}

/* 使用场景 */
.homeScenes {
  height: 858px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homeScenes_title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homeScenes_title > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #47b0b4;
  line-height: 30px;
}
.homeScenes_title > div:nth-of-type(2) {
  font-size: 20px;
  font-weight: 400;
  color: #47b0b4;
  line-height: 30px;
  margin-top: 20px;
  text-transform: uppercase;
}
.homeScenes_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  width: 1440px;
  overflow: hidden;
}
.homeScenes_content > div {
  position: relative;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}

.homeScenes_contentImg {
  width: 100%;
  height: 500px;
  display: flex;
  object-fit: cover;
}
.homeScenes_content > div:nth-of-type(1) > div:nth-of-type(2) {
  position: absolute;
  top: 66px;
  left: 54px;
}
.homeScenes_content
  > div:nth-of-type(1)
  > div:nth-of-type(2)
  > div:nth-of-type(1) {
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
  line-height: 54px;
}
.homeScenes_content
  > div:nth-of-type(1)
  > div:nth-of-type(2)
  > div:nth-of-type(2) {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
}

.homeScenes_content > div:nth-of-type(2) > div:nth-of-type(2) {
  position: absolute;
  top: 66px;
  left: 54px;
}
.homeScenes_content
  > div:nth-of-type(2)
  > div:nth-of-type(2)
  > div:nth-of-type(1) {
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
}
.homeScenes_content
  > div:nth-of-type(2)
  > div:nth-of-type(2)
  > div:nth-of-type(2) {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}

.homeScenes_content > div:nth-of-type(3) > div:nth-of-type(2) {
  position: absolute;
  top: 66px;
  left: 54px;
}
.homeScenes_content
  > div:nth-of-type(3)
  > div:nth-of-type(2)
  > div:nth-of-type(1) {
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
}
.homeScenes_content
  > div:nth-of-type(3)
  > div:nth-of-type(2)
  > div:nth-of-type(2) {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}
.homeScenes_contentInfo {
  width: 424px;
  height: 58px;
  transition: right 0.5s;
  position: absolute;
  bottom: 58px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}
/* 留言 */
.homeContact {
  height: 514px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homeContact > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #333333;
}
.homeContact > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 0;
  width: 828px;
}
.homeContact input {
  width: 380px;
  height: 54px;
  outline: none;
  border: 1px solid #47b0b4;
  background: #ffffff;
  font-size: 24px;
  font-weight: 400;
  padding: 0 20px;
  box-sizing: border-box;
}
input::-moz-placeholder {
  color: #999999;
}
input::-webkit-input-placeholder {
  color: #999999;
}
input::-ms-input-placeholder {
  color: #999999;
}
.homeContact > div:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeContact_btn {
  width: 360px;
  height: 60px;
  background: #47b0b4;
  color: #ffffff;
  font-weight: 400;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.homeContact_btn:hover {
  background-color: #329195;
}
/* App */
.homeApp {
  height: 480px;
  position: relative;
}
.homeApp_bg img {
  display: flex;
  width: 100%;
  height: 480px;
  object-fit: cover;
}
.homeApp_content {
  position: absolute;
  bottom: 80px;
  right: 240px;
}
.homeApp_bgTran {
  width: 920px;
  height: 320px;
  background: #000000;
  opacity: 0.4;
  border-radius: 20px;
}
.homeApp_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  width: 100%;
  height: 100%;
}
.homeApp_boxTitle > div:nth-of-type(1) {
  font-size: 44px;
  color: #ffffff;
}
.homeApp_boxTitle > div:nth-of-type(2) {
  font-size: 32px;
  color: #ffffff;
  text-transform : uppercase;
}
.homeApp_boxBtn {
  width: 616px;
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeApp_boxBtn i {
  font-size: 45px;
}
.homeApp_boxBtn > a {
  width: 280px;
  height: 82px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  text-decoration: none;
  color: #fff;
}
.homeApp_boxBtn > a:hover {
  background-color: #fff;
  color: #141110;
}
.homeApp_boxBtn_info > div:nth-of-type(1) {
  font-size: 18px;
  line-height: 18px;
}
.homeApp_boxBtn_info > div:nth-of-type(2) {
  font-size: 30px;
  line-height: 30px;
}
/* 淘宝 */
.homeTaoBao img {
  width: 100%;
  display: flex;
  height: 300px;
  object-fit: cover;
}
/* 最新动态 */
.homeDynamic {
  width: 1440px;
  margin: 0 auto;
  padding: 120px 0;
  box-sizing: border-box;
  height: 900px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.homeDynamic > div:nth-of-type(1) > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #47b0b4;
  text-align: center;
}
.homeDynamic > div:nth-of-type(1) > div:nth-of-type(2) {
  font-size: 20px;
  font-weight: 400;
  color: #47b0b4;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
}
.homeDynamic_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 60px 0;
}
.homeDynamic_box img {
  width: 288px;
  height: 216px;
  display: flex;
  object-fit: cover;
  margin-right: 20px;
}
.homeDynamic_box > div {
  width: calc(50% - 50px);
  cursor: pointer;
}
.homeDynamic_boxContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.homeDynamic_boxContent_info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.homeDynamic_boxContent_info > div:nth-of-type(1) {
  width: 100%;
  font-size: 20px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.homeDynamic_boxContent_info > div:nth-of-type(2) {
  font-size: 16px;
  color: #999999;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-indent: 2em;
}
.homeDynamic_boxContent_info > div:nth-of-type(3) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  /* font-weight: 400; */
  color: #cccccc;
  line-height: 27px;
}
.line {
  border: 1px solid #e0e0e0;
  margin: 20px 0;
}
.homeDynamic_boxList {
  margin-top: 60px;
}
.homeDynamic_boxList > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  cursor: pointer;
  color: #cccccc;
  font-size: 16px;
}
.homeDynamic_boxListText {
  width: 70%;
  font-size: 16px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.homeDynamic_boxListText > div:nth-of-type(2) {
  font-size: 18px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.homeDynamic_btn {
  width: 220px;
  height: 54px;
  border: 1px solid #47b0b4;
  font-size: 24px;
  font-weight: 400;
  color: #47b0b4;
  line-height: 23px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeDynamic_btn:hover {
  color: #fff;
  background-color: #47b0b4;
}
/* 友情链接 */
.homeLink {
  background: #f5f5f5;
  padding: 100px 0;
  box-sizing: border-box;
  height: 632px;
}
.homeLink > div {
  width: 1140px;
  margin: 0 auto;
}
.homeLink_title > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #47b0b4;
  text-align: center;
}
.homeLink_title > div:nth-of-type(2) {
  font-size: 20px;
  font-weight: 400;
  color: #47b0b4;
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
}
.homeLink_link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 80px;
  height: 240px;
}
.homeLink_link > div {
  width: 240px;
  height: 100px;
  margin: 0 30px;
  box-sizing: border-box;
}
.homeLink_link img {
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>
